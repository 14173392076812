import 'bootstrap'
import 'font-awesome/css/font-awesome.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './components/footer-component.js'
import './components/account/login-modal-component.js'
import './components/account/logout-modal-component.js'
import './components/account/forgot-password-modal-component.js'
import './components/account/resend-verification-email-component.js'
import './components/account/subscribe-component.js'
import './components/nav-bar/basic-nav-bar-component.js'
import './components/current-plan-component.js'
import './components/toast-component.js'
import './components/features-table-component.js'

import '../css/style.css'

import { initializeApp } from 'firebase/app'
import { getAuth, browserLocalPersistence } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCtSfIQyZua3V5lpmthL-Q49trkv9phV4g",
  authDomain: "webstructrual.firebaseapp.com",
  databaseURL: "https://webstructrual.firebaseio.com",
  projectId: "webstructrual",
  storageBucket: "webstructrual.appspot.com",
  messagingSenderId: "157062586915"
}

initializeApp(firebaseConfig)

const auth = getAuth()

initializeFirebaseAuth()

function initializeFirebaseAuth() {

  auth.setPersistence(browserLocalPersistence)

  auth.onAuthStateChanged(function(user) {
    if (user) {
      const event = new CustomEvent('customer-logged-in', {detail: user});
      document.dispatchEvent(event)
    } else {
      let event = new CustomEvent('customer-logged-out')
      document.dispatchEvent(event)
    }
  })
}


