import * as bootstrap from 'bootstrap'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCtSfIQyZua3V5lpmthL-Q49trkv9phV4g",
  authDomain: "webstructrual.firebaseapp.com",
  databaseURL: "https://webstructrual.firebaseio.com",
  projectId: "webstructrual",
  storageBucket: "webstructrual.appspot.com",
  messagingSenderId: "157062586915"
}

initializeApp(firebaseConfig)

//https://www.freecodecamp.org/news/reusable-html-components-how-to-reuse-a-header-and-footer-on-a-website/
class LoginModalComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = String.raw`    
      <div class="modal fade modal-signin py-5" id="modal-sign-in-form" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content rounded-4 shadow">
            <div class="modal-header p-5 pb-4 border-bottom-0">
              <h1 class="fw-bold mb-0 fs-2">Sign In</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body p-5 pt-0">

              <div id="modal-sign-in-error" class="alert alert-danger d-none"></div>

              <div class="form-floating mb-3">
                <input type="email" class="form-control rounded-3" id="sign-in-email" placeholder="name@example.com" autocomplete="email">
                <label for="sign-in-email">Email address</label>
              </div>
              <div class="form-floating mb-3">
                <input type="password" class="form-control rounded-3" id="sign-in-password" autocomplete="password" placeholder="current-password">
                <label for="sign-in-password">Password</label>
              </div>
              <button class="w-100 mb-2 btn btn-lg rounded-3 btn-primary" id="modal-sign-in-button" >
                Sign In
                <span id="modal-sign-in-spinner" class="spinner-border  spinner-border-sm d-none" role="status"></span>  
              </button>
              <a href="#" class="btn btn-sm btn-default btn-block fw-light text-secondary" data-bs-dismiss="modal" 
                id="modal-sign-in-forgot-password-button" data-bs-toggle="modal" data-bs-target="#modal-forgot-password-form">
                Forgot password
              </a>
              
            </div>
          </div>
        </div>
      </div>

    `

    document.getElementById('modal-sign-in-button').addEventListener('click', this.login)
  }

  login() {
    let email = document.getElementById('sign-in-email').value.trim()
    let password = document.getElementById('sign-in-password').value.trim()
    if(email === "") {
      document.getElementById('modal-sign-in-spinner').classList.add('d-none')
      document.getElementById('modal-sign-in-error').innerHTML = "Missing email address"
      document.getElementById('modal-sign-in-error').classList.remove('d-none')
      return
    }
    if(password === "") {
      document.getElementById('modal-sign-in-spinner').classList.add('d-none')
      document.getElementById('modal-sign-in-error').innerHTML = "Missing password"
      document.getElementById('modal-sign-in-error').classList.remove('d-none')
      return
    }


    document.getElementById('modal-sign-in-spinner').classList.remove('d-none')
    document.getElementById('modal-sign-in-error').innerHTML = ''
    document.getElementById('modal-sign-in-error').classList.add('d-none')

    signInWithEmailAndPassword(getAuth(), email, password)
      .then(function () {
        document.getElementById('modal-sign-in-spinner').classList.add('d-none')
        document.getElementById('modal-sign-in-error').innerHTML = ''
        bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-sign-in-form')).hide()
      })
      .catch((error) => {
        //console.error(error)
        let errorCode = error.code
        let errorMessage = error.message

        let str = errorMessage
        console.log(errorCode)
        if (errorCode == 'auth/invalid-email') {
          str = 'Invalid email address.'
        }
        if (errorCode === 'auth/missing-email') {
          str = 'Email is required.'
        }
        if (errorCode === 'auth/wrong-password') {
          str = 'Invalid password.'
        }
        if(errorCode === 'auth/user-not-found') {
          str = 'User not found.'
        }

        document.getElementById('modal-sign-in-spinner').classList.add('d-none')
        document.getElementById('modal-sign-in-error').innerHTML = str
        document.getElementById('modal-sign-in-error').classList.remove('d-none')
      })
  }
}

customElements.define('login-modal-component', LoginModalComponent)