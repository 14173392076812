import * as bootstrap from 'bootstrap'

class ToastComponent extends HTMLElement {
  constructor() {
    super();

    document.addEventListener('show-toast', async (event) => {
      console.log('show toast...')
      document.getElementById('toast-body').innerHTML = event.detail
      bootstrap.Toast.getOrCreateInstance(document.getElementById('project-toast')).show()
    }, true)
    
  }

  connectedCallback() {
    this.innerHTML = String.raw`
    <div class="toast-container position-fixed top-0 start-50 translate-middle-x p-3">
      <div id="project-toast" class="toast text-bg-primary" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div id="toast-body" class="toast-body">
          Hello, world! This is a toast message.
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
    `
  }
}

customElements.define('toast-component', ToastComponent)




