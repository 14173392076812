import { initializeApp } from 'firebase/app'
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { getDatabase, ref, set } from 'firebase/database'
import {loadStripe} from '@stripe/stripe-js'
import '../features-table-component.js'

const firebaseConfig = {
  apiKey: "AIzaSyCtSfIQyZua3V5lpmthL-Q49trkv9phV4g",
  authDomain: "webstructrual.firebaseapp.com",
  databaseURL: "https://webstructrual.firebaseio.com",
  projectId: "webstructrual",
  storageBucket: "webstructrual.appspot.com",
  messagingSenderId: "157062586915"
}

initializeApp(firebaseConfig)

class SubscribeComponent extends HTMLElement {

  constructor() {
    super();

    document.addEventListener('customer-logged-in', () => {
      this.checkStripeSubscriptionAndConfigure()
    }, true)
    document.addEventListener('customer-logged-out', () => {
      document.getElementById('subscribe-section').classList.remove('d-none')
      document.getElementById('create-account').classList.remove('d-none')
      document.getElementById('start-subscription').classList.add('d-none')
      document.getElementById('unlock-capabilities-section').classList.remove('d-none')
    }, true)
  }
  
  connectedCallback() {

    this.innerHTML = String.raw`

    <div id="unlock-capabilities-section" class="container px-4 py-4">
      <h2 class="text-center display-6">Unlock the full capabilities of WebStructural for <strong>$19/month</strong></h2>
      <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div class="col d-flex align-items-start">
          <div class="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            <i class="fa fa-save fa-fw bi" width="1em" height="1em" style="font-size: 1.5em;"></i>
          </div>
          <div>
            <h3 class="fs-2">Save & Print</h3>
            <p>Upgrade to save and print your designs. Create hard copies of your designs by printing to PDF.</p>
          </div>
        </div>
        <div class="col d-flex align-items-start">
          <div class="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            <i class="fa fa-unlock fa-fw bi" width="1em" height="1em" style="font-size: 1.5em;"></i>
          </div>
          <div>
            <h3 class="fs-2">No Restrictions</h3>
            <p>Unlock multiple spans, multi ply lumber, Engineering Lumber and more.</p>
          </div>
        </div>
        <div class="col d-flex align-items-start">
          <div class="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            <i class="fa fa-folder fa-fw bi" width="1em" height="1em" style="font-size: 1.5em;"></i>
          </div>
          <div>
            <h3 class="fs-2">Cancel Anytime</h3>
            <p>No long-term contract</strong>.
              Cancel anytime, we'll save your projects and you can resubscribe later to access them.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container" id="create-account">
      <div class="row">
        <div class="col-md-6 pb-5 mx-auto">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-body">
                <h1 class="h2 mb-2 fw-normal">Create an Account</h1>  

                <div id="modal-sign-up-error" class="form-floating alert alert-danger d-none">
                  Errors here
                </div>
            
                <div class="form-floating py-2">
                  <input type="name" class="form-control" id="sign-up-first-last" autocomplete="name" placeholder="First Last">
                  <label for="sign-up-first-last">Name</label>
                </div>

                <div class="form-floating py-2">
                  <input type="email" class="form-control" id="sign-up-email" autocomplete="email" placeholder="name@example.com">
                  <label for="sign-up-email">Email address</label>
                </div>

                <div class="form-floating py-2">
                  <input type="password" class="form-control" id="sign-up-password" autocomplete="password" placeholder="current-password">
                  <label for="sign-up-password">Password</label>
                </div>
            
                <button id='sign-up-button' class="w-100 btn btn-lg btn-primary" type="submit">
                  Sign Up
                  <span id='sign-up-spinner' class="spinner-border spinner-border-sm d-none" role="status"></span>  
                </button>
                
                <div class="form-group">
                  <a href="#" class="btn btn-sm btn-default btn-block fw-light text-secondary" 
                  data-bs-toggle="modal" data-bs-target="#modal-sign-in-form">Already have an account? Login</a>
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
 
    
    <div class="container d-none" id="start-subscription">
      <div class="col-md-6 pb-5 offset-md-3">
        <div class="card mb-6 rounded-3 shadow-sm">
          <div class="card-body">
            <h1 class="h2 mb-2 pb-3 fw-normal">Start Your Subscription</h1>  
            <div class="row">
              <div class="col-md-3 text-center text-primary">
                <h1>$19</h1>
                <p>monthly</p>
              </div>
              <div class="col-md-8">
                <form action="/charge" method="post" id="payment-form" role="form">
                  <div class="form-group pb-4">
                    <h4 style="margin-bottom:5px;" class="text-left">
                      Enter Credit or Debit Card Information to Upgrade 
                    </h4>
                    <p class="pb-2 text-muted fw-lighter text-secondary">
                      Checkout Powered by Stripe
                    </p>
                    <div id="card-element" class="py-3 form-control rounded-3">
                      <!-- A Stripe Element will be inserted here. -->
                    </div>

                    <!-- Used to display Element errors. -->
                    <div id="card-errors" role="alert"></div>

                  </div>
                  <div id="subscribe-response-error" class="alert alert-danger d-none"></div>
                  
                  <button id="submit-payment-button" class="w-100 btn btn-lg btn-primary" type="submit">
                    Submit Payment
                    <span id="submit-payment-spinner" class="spinner-border spinner-border-sm d-none" role="status"></span>  
                  </button>

                </form>
                <form>
                  <div class="form-group">
                    <a id="manage-subscription-link-button" class="btn btn-sm btn-default btn-block fw-light text-secondary" >
                      Already subscribed? Click here to manage your Subscription.</a>

                    <div id="subscribe-component-error" class="alert alert-danger d-none">
                      There was a problem getting your subscription. Please contact support.
                    </div>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
          <features-table-component></features-table-component>
          </div>
        </div>
      </div>
    </div>         
  
      `

    document.getElementById('manage-subscription-link-button').addEventListener('click', this.redirectToCustomerPortal)

    document.getElementById('sign-up-button').addEventListener('click', signup)

    initializeStripeCheckout()

  }

  checkStripeSubscriptionAndConfigure() {
    getAuth().currentUser.getIdToken().then(function(token) {
      fetch('https://us-central1-webstructrual.cloudfunctions.net/checkSubscription', {
        method: 'POST', 
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`
        },
      }).then((response) => {
        if(response.ok) {
          // logged in and upgraded
          document.getElementById('subscribe-section').classList.add('d-none')
        } else {
          // logged in but needs upgrade
          document.getElementById('subscribe-section').classList.remove('d-none')
          document.getElementById('create-account').classList.add('d-none')
          document.getElementById('start-subscription').classList.remove('d-none')
          document.getElementById('unlock-capabilities-section').classList.add('d-none')
        }
      }).catch( (error) => {
        console.error(error)
        // error -> assume not subscribed
        document.getElementById('subscribe-section').classList.remove('d-none')
        document.getElementById('create-account').classList.add('d-none')
        document.getElementById('start-subscription').classList.remove('d-none')
        document.getElementById('unlock-capabilities-section').classList.add('d-none')
      })
    })
  }

  redirectToCustomerPortal() {
    getAuth().currentUser.getIdToken().then(function(token) {
      document.getElementById("subscribe-component-error").classList.add("d-none")
        
      fetch('https://us-central1-webstructrual.cloudfunctions.net/customerPortal', {
        method: 'POST', 
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`
        },
      }).then((response) => {
        if(response.ok) {
          response.text().then((urlStr) => {
            window.location.replace(urlStr)
          })
        } else {
          document.getElementById("subscribe-component-error").classList.remove("d-none")
        }
      })
      .catch( (error) => {
        console.error(error)
        document.getElementById("subscribe-component-error").classList.remove("d-none")
      })
    })
  }
}


customElements.define('subscribe-component', SubscribeComponent);


async function initializeStripeCheckout() {
  //const STRIPE_API_KEY='pk_test_oTBdaj3DiPoh2GN2LRP6bgzS'
  const STRIPE_API_KEY='pk_live_2xaQxOvCEelcE2SQvmCtWQac'
  const stripe = await loadStripe(STRIPE_API_KEY);
  let elements = stripe.elements();

  // Custom styling can be passed to options when creating an Element.
  var style = {
    base: {
      // Add your base input styles here. For example:
      fontSize: '20px',
      color: "#32325d",
    }
  };

  if (typeof elements === 'undefined') {
    console.error("unable to create Stripe Card in initializeStripe(). elements undefined")
    return;
  }
  
  // Create an instance of the card Element.
  var card = elements.create('card', {style: style});

  if (typeof card === 'undefined') {
    console.error("unable to create Stripe Card in initializeStripe(). card undefined")
    return;
  }

  // Add an instance of the card Element into the `card-element` <div>.
  card.mount('#card-element');

  card.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  // Create a token or display an error when the form is submitted.
  var form = document.getElementById('payment-form');
  form.addEventListener('click', function(event) {
    event.preventDefault();
    stripe.createToken(card).then(function(result) {
      if (result.error) {
        // Inform the customer that there was an error.
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
        console.error(result.error.message);
      } else {
        // Send the token to your server.
        const event = new CustomEvent('show-toast', {detail: "<p>Subscription successful! <p>Welcome to WebStructural Pro."})
        document.dispatchEvent(event)
        stripeTokenHandler(result.token);
        //console.log(result.token);
      }
    });
  });
}

function stripeTokenHandler(token) {
  document.getElementById('submit-payment-spinner').classList.remove('d-none')
  document.getElementById('submit-payment-button').disabled = true

  var form = document.getElementById('payment-form');
  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripeToken');
  hiddenInput.setAttribute('value', token.id);
  form.appendChild(hiddenInput);

  var user = getAuth().currentUser
  var uid = user.uid
  var email = user.email
  var tok = token.id
  var card = token.card.id

  var dict = {uid: uid, email: email, token: tok, card: card};

  getAuth().currentUser.getIdToken().then(function(token) {
    fetch('https://us-central1-webstructrual.cloudfunctions.net/subscription', {
      method: 'POST', 
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(dict)
    }).then((response) => {
      document.getElementById('submit-payment-spinner').classList.add('d-none')
      document.getElementById('submit-payment-button').disabled = false
      document.getElementById('subscribe-response-error').classList.add('d-none')
      document.getElementById('subscribe-response-error').innerHTML = ''
      const event = new CustomEvent('customer-logged-in')
      document.dispatchEvent(event)
    }).catch( (error) => {
      document.getElementById('submit-payment-spinner').classList.add('d-none')
      document.getElementById('submit-payment-button').disabled = false
      document.getElementById('subscribe-response-error').classList.remove('d-none')
      document.getElementById('subscribe-response-error').innerHTML = error
    })
  })
}


function clearSignUpErrors() {
  document.getElementById('modal-sign-up-error').innerHTML = ''
  document.getElementById('modal-sign-up-error').classList.add('d-none')
}

function showSignupError(errorString) {
  document.getElementById('modal-sign-up-error').classList.remove('d-none')
  document.getElementById('modal-sign-up-error').innerHTML = errorString
  stopSignUpSpinner()
}

function startSignUpSpinner() {
  document.getElementById('sign-up-spinner').classList.remove('d-none')
  document.getElementById('sign-up-button').disabled = true
}

function stopSignUpSpinner() {
  document.getElementById('sign-up-spinner').classList.add('d-none')
  document.getElementById('sign-up-button').disabled = false
}

function signupTest() {
  // start spinner
  clearSignUpErrors()
  startSignUpSpinner()

  let firstLast = document.getElementById('sign-up-first-last').value.trim()
  let email = document.getElementById('sign-up-email').value.trim()
  let password = document.getElementById('sign-up-password').value.trim()

  if (!firstLast || !email || !password) {
    showSignupError('All fields required.')
    return
  }

  setTimeout(() => {
    stopSignUpSpinner()
    const event = new CustomEvent('show-toast', {detail: "<p>Registration successful! <p>Upgrade unlock WebStructural."})
    document.dispatchEvent(event)
  }, 100)
}

function signup() {
  clearSignUpErrors()
  startSignUpSpinner()
   
  let firstLast = document.getElementById('sign-up-first-last').value.trim()
  let email = document.getElementById('sign-up-email').value.trim()
  let password = document.getElementById('sign-up-password').value.trim()

  if (!firstLast || !email || !password) {
    showSignupError('All fields required.')
    return
  }

  createUserWithEmailAndPassword(getAuth(), email, password).then(() => {
    sendEmailVerification(getAuth().currentUser).catch((error) => {
      console.error(error)
      stopSignUpSpinner()
      showSignupError('Verification email could not be sent. Please try again later.')
    })
     
    set(ref(getDatabase(), 'users/' + getAuth().currentUser.uid), { username: firstLast }).then((docRef) => {
      const event = new CustomEvent('show-toast', {detail: "<p>Registration successful! <p>Upgrade unlock WebStructural."})
      document.dispatchEvent(event)
      stopSignUpSpinner()
    }).catch((error) => {
      console.error(error)
      showSignupError(error.message)
      stopSignUpSpinner()
    })
  }).catch((error) => {
    let str = error.message
    if(error.code === 'auth/email-already-in-use') {
      str = 'A user with that email address already exists.'
      str +=  '<a href="#" class="btn btn-sm btn-default btn-block fw-light " '
      str += ' data-bs-toggle="modal" data-bs-target="#modal-sign-in-form">Sign in now?</a>'
    }
    stopSignUpSpinner()
    showSignupError(str)
  })
}
