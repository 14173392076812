import { initializeApp } from 'firebase/app'
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCtSfIQyZua3V5lpmthL-Q49trkv9phV4g",
  authDomain: "webstructrual.firebaseapp.com",
  databaseURL: "https://webstructrual.firebaseio.com",
  projectId: "webstructrual",
  storageBucket: "webstructrual.appspot.com",
  messagingSenderId: "157062586915"
}

initializeApp(firebaseConfig)

class LogoutModalComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = String.raw`    

    <div class="modal fade modal-signin py-5 modal-sheet" tabindex="-1" role="dialog" id="modal-sign-out-form">
      <div class="modal-dialog" role="document">
        <div class="modal-content rounded-4 shadow">
          <div class="modal-footer flex-column border-top-0">
            <button type="button" id="modal-sign-out-button" data-bs-dismiss="modal" class="btn btn-lg btn-primary w-100 mx-0 mb-2">Sign Out</button>
            <button type="button" class="btn btn-lg btn-light w-100 mx-0" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    `

    document.getElementById('modal-sign-out-button').addEventListener('click', this.logout)
  }

  logout() {
    console.log('logout')
    getAuth().signOut().catch(function (error) { console.error(error) })
  }
}

customElements.define('logout-modal-component', LogoutModalComponent)