import { initializeApp } from 'firebase/app'
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCtSfIQyZua3V5lpmthL-Q49trkv9phV4g",
  authDomain: "webstructrual.firebaseapp.com",
  databaseURL: "https://webstructrual.firebaseio.com",
  projectId: "webstructrual",
  storageBucket: "webstructrual.appspot.com",
  messagingSenderId: "157062586915"
}

initializeApp(firebaseConfig)

const auth = getAuth()

class CurrentPlanComponent extends HTMLElement {

  constructor() {
    super();
    document.addEventListener('customer-logged-in', () => {
      this.checkStripeSubscriptionAndConfigure()
    }, true)
      document.addEventListener('customer-logged-out', () => {
      document.getElementById('basic-plan').classList.remove('d-none')
      document.getElementById('pro-plan').classList.add('d-none')
      document.getElementById('plan-section').classList.remove('d-none')
    }, true)
  }
  
  connectedCallback() {

    this.innerHTML = String.raw`

      <div class="px-3 py-3 my-4 text-center">
        <h2 id="current-plan-title" class="display-6 pb-2 fw-bold">Your Current Plan</h2>  
        
        <div id="basic-plan" class="col-4 mx-auto">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Basic</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">Free</small></h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>Steel and Wood Beam Design</li>
                <li>AISC and NDS Specifications</li>
                <li>LRFD and ASD Load Combinations</li>
                <li>2 Span Limit</li>
              </ul>
              <a type="button" href="#subscribe-section" class="w-100 btn btn-lg btn-primary">Upgrade now for $19<small class="fw-light">/mo.</a>
            </div>
          </div>
        </div> 

        <div id="pro-plan" class="col-4  mx-auto d-none">
          <div class="card mb-4 rounded-3 shadow-sm border-primary">
            <div class="card-header py-3 text-bg-primary border-primary">
              <h4 class="my-0 fw-normal">Pro</h4>
              <p class="my-0 fw-normal">Your account is up to date.</p>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">$19<small class="text-muted fw-light">/mo</small></h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>Save and Print Designs</li>
                <li>Multi Ply Lumber</li>
                <li>LVL and PSL Engineered Lumber</li>
                <li>Up to 10 Spans</li>
              </ul>
              <div id="manage-subscription-error" class="alert alert-danger d-none">There was a problem getting your subscription. Please try again later or contact support.</div>
              <a href="#" id="manage-subscription-button" class="w-100 btn btn-lg btn-primary">
                Manage Your Subscription
                <span id="manage-subscription-spinner" class="spinner-border spinner-border-sm d-none" role="status"></span>  
              </a>       
            </div>
          </div>
        </div>
      </div>
      `

    document.getElementById('manage-subscription-button').addEventListener('click', this.redirectToCustomerPortal)
  }

  async checkStripeSubscriptionAndConfigure() {
    auth.currentUser.getIdToken().then(function(token) {
      fetch('https://us-central1-webstructrual.cloudfunctions.net/checkSubscription', {
        method: 'POST', 
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`
        },
      }).then((response) => {
        if(response.ok) {
          // logged in and upgraded
          document.getElementById('plan-section').classList.remove('d-none')
          document.getElementById('basic-plan').classList.add('d-none')
          document.getElementById('pro-plan').classList.remove('d-none')
        } else {
          // logged in but needs upgrade
          document.getElementById('plan-section').classList.add('d-none')
          document.getElementById('basic-plan').classList.add('d-none')
          document.getElementById('pro-plan').classList.add('d-none')
        }
      }).catch( (error) => {
        console.error(error)
        // error -> assume not subscribed
        document.getElementById('basic-plan').classList.add('d-none')
        document.getElementById('pro-plan').classList.add('d-none')
        document.getElementById('plan-section').classList.remove('d-none')
      })
    })
  }

  redirectToCustomerPortal() {
    auth.currentUser.getIdToken().then(function(token) {
      document.getElementById("manage-subscription-error").classList.add("d-none");
      document.getElementById("manage-subscription-spinner").classList.remove("d-none");
        
      fetch('https://us-central1-webstructrual.cloudfunctions.net/customerPortal', {
        method: 'POST', 
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`
        },
      }).then((response) => {
        if(response.ok) {
          response.text().then((urlStr) => {
            window.location.replace(urlStr)
          })
        } else {
          document.getElementById("manage-subscription-error").classList.remove("d-none");
          document.getElementById("manage-subscription-spinner").classList.add("d-none");
        }
      })
      .catch( (error) => {
        console.error(error)
        document.getElementById("manage-subscription-error").classList.remove("d-none");
        document.getElementById("manage-subscription-spinner").classList.add("d-none");
      })
    })
  }
}


customElements.define('current-plan-component', CurrentPlanComponent)