import { initializeApp } from 'firebase/app'
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCtSfIQyZua3V5lpmthL-Q49trkv9phV4g",
  authDomain: "webstructrual.firebaseapp.com",
  databaseURL: "https://webstructrual.firebaseio.com",
  projectId: "webstructrual",
  storageBucket: "webstructrual.appspot.com",
  messagingSenderId: "157062586915"
}

initializeApp(firebaseConfig)

const auth = getAuth()

//https://www.freecodecamp.org/news/reusable-html-components-how-to-reuse-a-header-and-footer-on-a-website/
class ResendVerificationEmailComponent extends HTMLElement {
  constructor() {
    super();
    document.addEventListener('customer-logged-in', () => {
      let user = auth.currentUser
      if(user.emailVerified === false) {
        document.getElementById('resend-verification-email-form').classList.remove('d-none')
      }
    }, true)
    document.addEventListener('customer-logged-out', () => {
      document.getElementById('resend-verification-email-form').classList.add('d-none')
    }, true)
  }

  connectedCallback() {
    this.innerHTML = String.raw`
    <div id="resend-verification-email-form" class="col-md-6 mx-auto py-4 d-none">
      <div class="row">
        <div id="resend-verification-email-alerts">
          <div id="resend-verification-email-sent-success-alert" class="alert alert-success alert-dismissible fade show d-none"  role="alert">
            A verification email has been sent. Please check your email.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>

          <div id="resend-verification-email-sent-failure-alert" class="alert alert-danger alert-dismissible fade show d-none"  role="alert">
            Verification email could not be sent. Please try again later.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex align-items-start alert alert-secondary">
          <div class="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
            <i class="fa fa-envelope fa-2x" width="1em" height="1em" style="font-size: 1.5em;"></i>
          </div>
          <div>
            <h3 class="fs-2">Don't forget to verify your email.</h3>
            <p>A verification link has been sent to <strong class="signed-in-as-email"></strong>.
              Please verify your email address by following the link in the email we sent you.</p>

              <a id="resend-verification-email-button" class="btn btn-sm btn-outline-primary" href="#">
                Resend verification link
                <span id="resend-verification-email-spinner" class="spinner-border spinner-border-sm d-none" role="status"></span>  
              </a>
          </div>
        </div>
      </div>
    </div>
    `

    document.getElementById('resend-verification-email-button').addEventListener('click', this.resendVerificationEmail)

  }

  
  resendVerificationEmail() {
    let user = auth.currentUser
    console.log(user)

    user.sendEmailVerification().then(function() {
      const alertDiv = document.createElement("div")
      alertDiv.innerHTML = String.raw`
        <div id="resend-verification-email-sent-success-alert" class="alert alert-success alert-dismissible fade show"  role="alert">
          A verification email has been sent. Please check your email.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      `
      document.getElementById('resend-verification-email-alerts').appendChild(alertDiv)
    }).catch(function(error) {
      const alertDiv = document.createElement("div")
      alertDiv.innerHTML = String.raw`
        <div id="resend-verification-email-sent-failure-alert" class="alert alert-danger alert-dismissible fade show"  role="alert">
          Verification email could not be sent. Please try again later or contact support.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      `
      document.getElementById('resend-verification-email-alerts').appendChild(alertDiv)
      console.log(error)
    })
  }
}

customElements.define('resend-verification-email-component', ResendVerificationEmailComponent)