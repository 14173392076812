import * as bootstrap from 'bootstrap'
import { initializeApp } from 'firebase/app'
import { getAuth, sendPasswordResetEmail } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCtSfIQyZua3V5lpmthL-Q49trkv9phV4g",
  authDomain: "webstructrual.firebaseapp.com",
  databaseURL: "https://webstructrual.firebaseio.com",
  projectId: "webstructrual",
  storageBucket: "webstructrual.appspot.com",
  messagingSenderId: "157062586915"
}

initializeApp(firebaseConfig)

let auth = getAuth()

class ForgotPasswordModalComponent extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = String.raw`
    <div class="modal fade py-5" id="modal-forgot-password-form" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content rounded-4 shadow">
          <div class="modal-header p-5 pb-4 border-bottom-0">
            <h1 class="fw-bold mb-0 fs-2">Forgot Password?</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-5 pt-0">
            <div class="">  
              <div id="modal-forgot-password-error" class="alert alert-danger d-none"></div>
    
              <div class="form-floating mb-3">
                <input type="email" class="form-control rounded-3" id="forgot-password-email" placeholder="name@example.com" autocomplete="email">
                <label for="forgot-password-email">Email address</label>
              </div>
                
              <button class="w-100 mb-2 btn btn-lg rounded-3 btn-primary" id="send-forgot-password-email-button" >
                Reset Password
                <span id="forgot-password-spinner" class="spinner-border  spinner-border-sm d-none" role="status"></span>  
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal fade py-5" id="modal-forgot-password-email-sent" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content rounded-4 shadow">
          <div class="modal-header p-5 pb-4 border-bottom-0">
            <h1 class="fw-bold mb-0 fs-3">Password reset email sent.</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-5 pt-0">
            <p>Check your email for the link.</p>
          </div>
        </div>
      </div>
    </div>
    `

    document.getElementById('send-forgot-password-email-button').addEventListener('click', this.sendForgotPasswordEmail)
  }

  sendForgotPasswordEmail() {
    document.getElementById('forgot-password-spinner').classList.remove('d-none');
    document.getElementById('modal-forgot-password-error').innerHTML = '';
    document.getElementById('modal-forgot-password-error').classList.add('d-none');
    
    let email = document.getElementById('forgot-password-email').value.trim()

    if(email === "") {
      document.getElementById('forgot-password-spinner').classList.add('d-none')
      document.getElementById('modal-forgot-password-error').innerHTML = "Missing email address"
      document.getElementById('modal-forgot-password-error').classList.remove('d-none')
      return
    }

    // testing...
    /*setTimeout(() => {
      console.log(email)
      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-forgot-password-form')).hide()
      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-forgot-password-email-sent')).show()
      document.getElementById('forgot-password-spinner').classList.add('d-none');
    }, 1000)*/
    // end testing

    sendPasswordResetEmail(auth, email).then(function() {
      // Email sent.
      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-forgot-password-form')).hide()
      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-forgot-password-email-sent')).show()
      document.getElementById('forgot-password-spinner').classList.add('d-none');
    }).catch(function(error) {
      let errorMessage = error.message;
      document.getElementById('forgot-password-spinner').classList.add('d-none')
      document.getElementById('modal-forgot-password-error').innerHTML = errorMessage
      document.getElementById('modal-forgot-password-error').classList.remove('d-none')
    });  
  }
}

customElements.define('forgot-password-modal-component', ForgotPasswordModalComponent);
