class FooterComponent extends HTMLElement {
  connectedCallback() {
    this.innerHTML = String.raw`
  <footer class="py-5" style="background:#f7fbfe;">
    <div class="row" style="padding:20px;">
      <div class="col-6 col-md-2 mb-3">
        <p><a class="d-flex align-items-center mb-3 link-dark text-decoration-none" href="account.html">My Account</a></p>    
        <p><a class="d-flex align-items-center mb-3 link-dark text-decoration-none" href="about.html">About</a></p>
        <p><a class="d-flex align-items-center mb-3 link-dark text-decoration-none" href="eula.html">Terms of Service</a></p>
        <p><a class="d-flex align-items-center mb-3 link-dark text-decoration-none" href="mailto:jeff@webstructural.com">Support</a></p>
        <p class="text-muted">&copy; <strong><span>WebStructural 2023</span></strong></p>
      </div>

      <div class="col mb-3"></div>

      <div class="col mb-3">
        <h5>Software & Tools</h5>
        <ul class="nav flex-column">
        <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="beam-designer.html">Beam Designer</a></li>
        <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="anchor-bolt-designer.html">Anchor Bolt Designer</a></li>
        <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="footing-designer.html">Concrete Footing Designer</a></li>
        <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="shear-and-moment-diagram.html">Shear and Moment Diagrams</a></li>
        <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="beam-calculator.html">Beam Calculator</a></li>
        <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="cross-section-calculator.html">Shape Calculator</a></li>
        </ul>
      </div>

      <div class="col mb-3">
        <h5>Tutorials</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="steel-beam-how-to.html">How to Design a Steel Beam</a></li>
          <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="flitch-beam-how-to.html">How to Design a Wood Flitch Beam</a></li>
          <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="save-steel-and-wood-beam-designs.html">How to Save a Beam Design</a></li>
          <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="load-cases-and-load-combinations.html">Load Cases and Combinations</a></li>
          <li class="nav-item mb-2"><a class="nav-link p-0 text-muted" href="#">How to Design an Anchor Bolt - Coming Soon</a></li>
        </ul>
      </div>
    </div>
  </footer>
      `
  }
}

customElements.define('footer-component', FooterComponent);