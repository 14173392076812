import { initializeApp } from 'firebase/app'
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCtSfIQyZua3V5lpmthL-Q49trkv9phV4g",
  authDomain: "webstructrual.firebaseapp.com",
  databaseURL: "https://webstructrual.firebaseio.com",
  projectId: "webstructrual",
  storageBucket: "webstructrual.appspot.com",
  messagingSenderId: "157062586915"
}

initializeApp(firebaseConfig)

class BasicNavBarComponent extends HTMLElement {

  constructor() {
    super();
    document.addEventListener('customer-logged-in', () => {
      document.getElementById('login-button').classList.add('d-none')
      document.getElementById('signed-in-as-nav').classList.remove('d-none')

      document.getElementById('signed-in-as').innerHTML = getAuth().currentUser.email
      
      //const dbRef = ref(getDatabase())

      
      /*get(child(dbRef, `users/${getAuth().currentUser.uid}`)).then((snapshot) => {
        if (snapshot.exists()) {
          if(snapshot.val().username) {
            //document.getElementById('signed-in-as').innerHTML = snapshot.val().username
            document.getElementById('signed-in-as').innerHTML = user.email
          //} else {
          //  document.getElementById('signed-in-as').innerHTML = user.email
          //}
          }
        } else {
          console.log("No data available")
        }
      }).catch((error) => {
        console.error(error)
      })*/
    }, true)
    document.addEventListener('customer-logged-out', () => {
      document.getElementById('login-button').classList.remove('d-none')
      document.getElementById('signed-in-as-nav').classList.add('d-none')
    }, true)
  }
  
  connectedCallback() {

    this.innerHTML = String.raw`

  <div class="container" style="font-size:1.1em;">
    <header class="d-flex flex-wrap justify-content-center py-3">
      <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <span class="display-6 fs-2"><img src="./images/simple-webstructural-cropped.png" width="50px"> WebStructural</span>
      </a>
  
      <ul class="nav nav-pills">
        <li class="nav-item dropdown">
          <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-th fa-fw"></i> Apps
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="beam-designer.html">Beam Designer</a></li>
            <li><a class="dropdown-item" href="anchor-bolt-designer.html">Anchor Bolt Designer</a></li>
            <li><a class="dropdown-item" href="footing-designer.html">Concrete Footing Designer</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="shear-and-moment-diagram.html">Shear and Moment Calculator</a></li>
            <li><a class="dropdown-item" href="beam-calculator.html">Beam Calculator</a></li>
            <li><a class="dropdown-item" href="cross-section-calculator.html">Cross Section Properties</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Tutorials
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="steel-beam-how-to.html">How to Design a Steel Beam</a></li>
            <li><a class="dropdown-item" href="flitch-beam-how-to.html">How to Design a Wood Flitch Beam</a></li>
            <li><a class="dropdown-item" href="save-steel-and-wood-beam-designs.html">How to Save a Beam Design</a></li>
            <li><a class="dropdown-item" href="load-cases-and-load-combinations.html">Load Cases and Combinations</a></li>
          </ul>
        </li>
        <li id="login-button"><a class="nav-link btn-nav-login" href="beam-designer.html?login=true">Log in</a></li>
        <li id="signed-in-as-nav" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span id="signed-in-as">Pro Customer</span>
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="account.html">My Account</a></li>
            <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modal-sign-out-form">Logout</a></li>
          </ul>
        </li>
        <li class="nav-item"><a href="beam-designer.html" class="nav-link active">Start Designing</a></li>
      </ul>
    </header>
  </div>
      `

    if(this.getAttribute('modal-login')) {
      let el = document.getElementById("login-button")
      el.innerHTML = '<a class="nav-link btn-nav-login" href="#" data-bs-toggle="modal" data-bs-target="#modal-sign-in-form">Log In</a>'
    }
  }
}


customElements.define('basic-nav-bar-component', BasicNavBarComponent);