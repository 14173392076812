class FeaturesTableComponent extends HTMLElement {
  connectedCallback() {
    this.innerHTML = String.raw`
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th class="text-center">
              <h4>Basic</h4>
              <h6>Free</h6>
            </th>
            <th class="text-center">
              <h4>Pro</h4>
              <h6>$19 monthly</h6>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><i class="fa fa-save fa-fw"></i>Save Projects</td>
            <td class="text-center"><i class="fa fa-times text-danger fs-4"></i></td>
            <td class="text-center"><i class="fa fa-check text-success fs-4"></i></td>
          </tr>
          <tr>
            <td><i class="fa fa-print fa-fw"></i>Print Results</td>
            <td class="text-center"><i class="fa fa-times text-danger fs-4"></i></td>
            <td class="text-center"><i class="fa fa-check text-success fs-4"></i></td>
          </tr>
          <tr>
            <td><i class="fa fa-ellipsis-h fa-fw"></i> Multiple Spans (more than 2)</td>
            <td class="text-center"><i class="fa fa-times text-danger fs-4"></i></td>
            <td class="text-center"><i class="fa fa-check text-success fs-4"></i></td>
          </tr>
          <tr>
            <td><i class="fa fa-arrows fa-fw"></i> HSS Strong and Weak Axis Analysis</td>
            <td class="text-center"><i class="fa fa-times text-danger fs-4"></i></td>
            <td class="text-center"><i class="fa fa-check text-success fs-4"></i></td>
          </tr>
          <tr>
            <td><i class="fa fa-globe fa-fw"></i> European Steel Shape Analysis</td>
            <td class="text-center"><i class="fa fa-times text-danger fs-4"></i></td>
            <td class="text-center"><i class="fa fa-check text-success fs-4"></i></td>
          </tr>
          <tr>
            <td><i class="fa fa-user fa-fw"></i> Custom Shape Analysis</td>
            <td class="text-center"><i class="fa fa-times text-danger fs-4"></i></td>
            <td class="text-center"><i class="fa fa-check text-success fs-4"></i></td>
          </tr>
          <tr>
            <td><i class="fa fa-ellipsis-h fa-fw"></i> Multi ply lumber</td>
            <td class="text-center"><i class="fa fa-times text-danger fs-4"></i></td>
            <td class="text-center"><i class="fa fa-check text-success fs-4"></i></td>
          </tr>
          <tr>
            <td><i class="fa fa-calculator fa-fw"></i> LVL and PSL Engineered Lumber</td>
            <td class="text-center"><i class="fa fa-times text-danger fs-4"></i></td>
            <td class="text-center"><i class="fa fa-check text-success fs-4"></i></td>
          </tr>
          <tr>
            <td><i class="fa fa-shovel fa-fw"></i> Concrete Footing Designer</td>
            <td class="text-center"><i class="fa fa-times text-danger fs-4"></i></td>
            <td class="text-center"><i class="fa fa-check text-success fs-4"></i></td>
          </tr>
          <tr>
            <td><i class="fa fa-wrench fa-fw"></i> Concrete Anchor Designer</td>
            <td class="text-center"><i class="fa fa-times text-danger fs-4"></i></td>
            <td class="text-center"><i class="fa fa-check text-success fs-4"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
    `
  }
}

customElements.define('features-table-component', FeaturesTableComponent)